.navbar-container {
  display: flex;
  width: 100%;
  margin: 24px 0;
  padding-right: 40px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  position: relative;
  justify-content: end;
  
  a, .button-login {
    font-family: 'Roboto';
    // font-weight: 500;
    text-decoration: none;
    color: white;
    font-size: 17px;
    transition: text-shadow 0.1s;

    &:hover {
      text-shadow: 0 1px 20px #f7f7f7;
    }
  }

  a.active {
    text-decoration: underline;
  }

  a.black, .button-login.black {
    color: #212121;
  }
}

.navbar {
  display: flex;
  gap: 32px;
  width: 100%;
  justify-content: end;
}

.black-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.button-login {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.navbar-button {
  width: 20px;
  height: 20px;
}
.navbar-button {
  display: none!important;
}

@media only screen and (max-width: 780px) {
  .navbar {
    display: none!important;
  }

  .navbar-button {
    display: block!important;
  }

  .mobile-navbar {
    flex-direction: column;
    gap: 12px;
    position: absolute;
    top: 45px;
    background: #efefef;
    width: 100%;
    z-index: 1000;
    left: 0;
    height: 180px;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
