.drawer {
  width: 25%;
  height: 100%;
  transition: margin .3s ease-in-out;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
  min-width: 300px;
  height: calc(100vh - 141px);
}

.opened {
  margin-right: 0;
  width: auto;
}

.closed {
  margin-right: calc(min(-25%, -300px));
  width: 0px;
  min-width: 0px!important;
}

.dynamic-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

@media only screen and (max-width: 600px) {
  .drawer {
    width: 100%;
  }
}

@media only screen and (max-width: 685px) {
  .drawer {
    height: calc(100vh - 189px);
  }
}