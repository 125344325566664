.logo {
  color: #000 !important;
  word-spacing: 30px !important;
  font-family: Lobster !important;
  font-size: 32px !important;
  position: absolute !important;
  top: -10px !important;
  left: 24px !important;
}

.navbar-container {
  border-bottom: 1px solid #ffffff1f;
  justify-content: end;
  width: 100%;
  margin: 24px 0;
  padding-bottom: 24px;
  padding-right: 40px;
  display: flex;
  position: relative;
}

.navbar-container a, .navbar-container .button-login {
  color: #fff;
  font-family: Roboto;
  font-size: 17px;
  text-decoration: none;
  transition: text-shadow .1s;
}

.navbar-container a:hover, .navbar-container .button-login:hover {
  text-shadow: 0 1px 20px #f7f7f7;
}

.navbar-container a.active {
  text-decoration: underline;
}

.navbar-container a.black, .navbar-container .button-login.black {
  color: #212121;
}

.navbar {
  justify-content: end;
  gap: 32px;
  width: 100%;
  display: flex;
}

.black-border {
  border-bottom: 1px solid #0000001f;
}

.button-login {
  cursor: pointer;
  background-color: #0000;
  border: none;
}

.navbar-button {
  width: 20px;
  height: 20px;
  display: none !important;
}

@media only screen and (max-width: 780px) {
  .navbar {
    display: none !important;
  }

  .navbar-button {
    display: block !important;
  }

  .mobile-navbar {
    z-index: 1000;
    background: #efefef;
    border-bottom: 1px solid #0000001f;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 180px;
    position: absolute;
    top: 45px;
    left: 0;
  }
}

.video-view {
  width: 100%;
  max-height: 90%;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 600px) {
  .video-view {
    max-width: unset !important;
    position: initial !important;
    width: 100% !important;
    height: auto !important;
    top: 0 !important;
    left: 0 !important;
    transform: none !important;
  }

  .amv-view-container {
    height: auto !important;
  }
}

.amv-view-container {
  width: 100%;
  height: calc(100dvh - 144px);
  position: relative;
}

.video-center {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-area-header-container {
  justify-content: center;
  width: 100%;
  display: flex;
}

.main-area-header-container button {
  padding: 12px;
}

.main-area-header-container svg, .main-area-header-container svg * {
  color: #000000de;
}

@media only screen and (max-width: 685px) {
  .main-area-header-container {
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  }
}

.hide-icon {
  width: 0;
  transform: scale(0) !important;
}

.icon-button {
  transform: scale(1);
  transition: all .3s cubic-bezier(.39, .575, .565, 1) !important;
}

.primary-text {
  flex-flow: row;
  align-items: center;
  display: flex;
}

.hide {
  display: none;
}

.amv-item {
  cursor: pointer;
  flex-flow: column;
  flex: 1 0 auto;
  width: 250px;
  max-width: 250px;
  height: 264px;
  margin: 10px;
  transition: box-shadow .5s cubic-bezier(.25, .8, .25, 1);
  display: flex;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.amv-item:hover {
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
}

.amv-item:hover .amv-title {
  background-color: #000;
}

.amv-preview {
  width: 100%;
  height: 140px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.amv-img {
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 100%;
  transition: left 1s cubic-bezier(.25, .8, .25, 1);
  position: absolute;
  left: 0;
  overflow: hidden;
}

.amv-title {
  text-align: center;
  box-sizing: border-box;
  z-index: 1;
  color: #fff;
  background: linear-gradient(to top, #000000b3 0%, #0000004d 70%, #0000 100%);
  width: 100%;
  margin: 0;
  padding: 10px 5px 5px;
  font-size: 12px;
  font-weight: 300;
  transition: background-color .5s cubic-bezier(.25, .8, .25, 1);
  position: absolute;
  bottom: 0;
}

.amv-info {
  padding: 10px;
  font-size: 14px;
}

.amv-dates {
  font-size: 13px;
  font-weight: 400px;
  color: silver;
  justify-content: space-between;
  margin: 10px 0 5px;
  padding: 0 10px;
  display: flex;
}

.amv-info-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  overflow: hidden;
}

.amv-info-text:last-child {
  margin-top: 5px;
}

.amv-misc {
  color: silver;
  justify-content: space-between;
  padding: 0 10px 5px;
  font-size: 13px;
  display: flex;
}

.amv-misc-icon {
  margin-right: 5px;
  width: 16px !important;
  height: 16px !important;
}

.amv-misc-item-group {
  align-items: center;
  transition: color .3s ease-in-out;
  display: flex;
}

.amv-misc-item-group:hover {
  color: #000;
}

.amv-date-item {
  transition: color .3s ease-in-out;
}

.amv-date-item:hover {
  color: #000;
}

.filter {
  box-sizing: border-box;
  cursor: pointer;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 5px 10px;
  transition: background-color .3s cubic-bezier(.25, .8, .25, 1);
  display: flex;
}

.filter:hover, .filter.active {
  background-color: #0000000d;
}

.filter-body:last-child {
  margin: 0;
}

.secondary-text {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  display: flex;
}

.info {
  color: #a0a0a0;
  cursor: pointer;
  margin-right: 5px;
  font-size: 12px;
  font-weight: 300;
  transition: color .5s cubic-bezier(.25, .8, .25, 1);
  position: relative;
}

.info:hover {
  color: #000;
}

.selected {
  background-color: #0000000d;
}

.edit-file-fields {
  flex-direction: column;
  gap: 14px;
  display: flex;
}

.edit-container {
  flex-direction: column;
  gap: 22px;
  display: flex;
}

.drawer {
  box-sizing: border-box;
  width: 25%;
  min-width: 300px;
  height: calc(100vh - 141px);
  padding: 10px;
  transition: margin .3s ease-in-out;
  overflow: auto;
}

.opened {
  width: auto;
  margin-right: 0;
}

.closed {
  width: 0;
  margin-right: min(-25%, -300px);
  min-width: 0 !important;
}

.dynamic-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media only screen and (max-width: 600px) {
  .drawer {
    width: 100%;
  }
}

@media only screen and (max-width: 685px) {
  .drawer {
    height: calc(100vh - 189px);
  }
}

.main-area {
  width: 100%;
  height: 100vh;
}

.amv-grid {
  box-sizing: border-box;
  flex-flow: wrap;
  justify-content: center;
  width: 100%;
  height: 100vh;
  max-height: calc(100dvh - 141px);
  padding-bottom: 20px;
  display: flex;
  overflow: auto;
}

.main-area-drawer {
  display: none;
}

@media only screen and (max-width: 600px) {
  .main-area {
    width: 100%;
  }

  .main-area-drawer {
    display: block;
  }

  .amv-grid {
    height: calc(100dvh - 189px);
  }
}

.container {
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
}

.container-drawer {
  margin-top: 48px;
  padding-top: 0;
  display: block;
}

@media only screen and (max-width: 600px) {
  .container-drawer {
    display: none;
  }
}

.upload-file {
  white-space: nowrap;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.upload-file::file-selector-button {
  cursor: pointer;
}

.upload-file-fields {
  flex-direction: column;
  gap: 18px;
  margin: 24px 0;
  display: flex;
}

.upload-container {
  width: 60%;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .upload-container {
    width: 90%;
  }
}

.modalContent {
  flex-direction: column;
  gap: 12px;
  display: flex;
}

.close {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.close h2 {
  margin: 0 0 14px;
}

.close svg {
  cursor: pointer;
}

.textarea {
  background: none;
  border-color: #0000003b;
  border-radius: 4px;
  padding: 8.5px 15px;
}

.list {
  background-color: #0000;
  flex-direction: column;
  gap: 14px;
  width: 90%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.item {
  background-color: #fbfbfb;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
  transition: box-shadow .5s cubic-bezier(.25, .8, .25, 1);
  display: flex;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
}

.item:hover {
  box-shadow: 0 14px 28px #00000040, 0 10px 10px #00000038;
}

.landing-video {
  z-index: -99999;
  filter: brightness(50%);
  object-fit: cover;
  width: 100dvw;
  height: 100dvh;
  margin-left: 50dvw;
  position: absolute;
  top: 0;
  overflow: hidden;
  transform: translate(-50%);
}

.main-text {
  text-align: center;
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

h1 {
  color: #fff;
  word-spacing: 30px;
  margin-bottom: 20px;
  font-family: Lobster;
  font-size: 72px;
}

.button-watch:not(:hover) {
  box-shadow: 0 0 23.84px 8.16px #ffabab63;
}

small {
  color: #fff;
  font-family: Raleway;
  font-size: 32px;
  font-weight: 200;
}

.extension {
  color: #fff;
  align-items: center;
  gap: 12px;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 200;
  text-decoration: underline;
  display: flex;
  position: absolute;
  bottom: 29px;
  right: 36px;
}

.extension img {
  height: 32px;
}

.button-upload-youtube {
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 7px;
  margin-top: 40px;
  padding: 15px 24px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .2s;
  display: flex;
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
}

.button-upload-youtube:hover {
  box-shadow: none;
}

body {
  color: #212121;
  background-color: #f1f1f1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 14px;
  overflow: hidden;
}

.ReactModal__Overlay {
  z-index: 999;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  color: initial;
  text-decoration: none;
}

html, #app {
  width: 100%;
  height: 100%;
}

p {
  margin: 0;
}

textarea, input, button, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  width: 100%;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #0000;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  background-clip: content-box;
  border: 6px solid #0000;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.ReactVirtualized__Grid {
  overflow: visible scroll !important;
}

.drawer .amv-info {
  flex-direction: column;
  gap: 22px;
  padding-top: 0;
  display: flex;
}

.drawer .amv-info h1, .drawer .amv-info h2, .drawer .amv-info h3 {
  color: #212121;
  white-space: wrap;
  word-spacing: 2px;
  margin: 6px 0 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
}

/*# sourceMappingURL=index.358a71ce.css.map */
