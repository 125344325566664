.video-view {
  width: 100%;
  max-height: 90%;
  top: 0;
  left: 0;
  position: absolute;
  // transition: all 200ms;
  // transition-timing-function: linear;
}

@media only screen and (max-width: 600px) {
  .video-view {
    height: auto!important;
    width: 100%!important;
    max-width: unset!important;
    position: initial!important;
    left: 0!important;
    top: 0!important;
    transform: none!important;
  }

  .amv-view-container {
    height: auto!important;
  }
}

.amv-view-container {
  width: 100%;
  height: calc(100dvh - 144px);
  position: relative;
}

.video-center {
  width: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}