.primary-text {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
}

.hide {
  display: none;
}

.amv-item {
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  transition: box-shadow .5s cubic-bezier(.25, .8, .25, 1);
  cursor: pointer;
  margin: 10px;
  width: 250px;
  max-width: 250px;;
  height: 264px;
}

.amv-item:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
}

.amv-item:hover .amv-title {
  background-color: black;
}

.amv-preview {
  display: flex;
  height: 140px;
  position: relative;
  overflow: hidden;
  width: 100%;
}

.amv-img {
  overflow: hidden;
  position: absolute;
  left: 0px;
  transition: left 1s cubic-bezier(.25, .8, .25, 1);
  width: 100%;
  height: auto;
  min-height: 100%;
  object-fit: cover;
}

.amv-title {
  font-size: 12px;
  text-align: center;
  font-weight: 300;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  position: absolute;
  z-index: 1;
  color: white;
  bottom: 0;
  padding: 10px 5px 5px;
  background: linear-gradient(to top,
    rgba(0,0,0,0.7) 0%,
    rgba(0,0,0,0.3) 70%,
    rgba(0,0,0,0) 100%);
  transition: background-color .5s cubic-bezier(.25, .8, .25, 1);
}

.amv-info {
  font-size: 14px;
  padding: 10px;
}

.amv-dates {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 5px;
  font-size: 13px;
  font-weight: 400px;
  color: #C0C0C0;
  padding: 0 10px;
}

.amv-info-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
}

.amv-info-text:last-child {
  margin-top: 5px;
}

.amv-misc {
  font-size: 13px;
  color: #C0C0C0;
  display: flex;
  justify-content: space-between;
  padding: 0 10px 5px;
}

.amv-misc-icon {
  height: 16px !important;
  width: 16px !important;
  margin-right: 5px;
}

.amv-misc-item-group {
  display: flex;
  align-items: center;
  transition: color .3s ease-in-out;
}

.amv-misc-item-group:hover {
  color: black;
}

.amv-date-item {
  transition: color .3s ease-in-out;
}

.amv-date-item:hover {
  color: black;
}
