.main-area-header-container {
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    padding: 12px;
  }
  
  svg {
    color: rgba(0, 0, 0, 0.87);
    * {
      color: rgba(0, 0, 0, 0.87);
    }
  }
}

@media only screen and (max-width: 685px) {
  .main-area-header-container {
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  }
}

.hide-icon {
  transform: scale(0)!important;
  width: 0;
}

.icon-button {
  transform: scale(1);
  transition: all .3s cubic-bezier(0.39, 0.575, 0.565, 1)!important;
}