.list {
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: transparent;
  width: 90%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  background-color: #fbfbfb;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  transition: box-shadow .5s cubic-bezier(.25, .8, .25, 1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.item:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22);
}

.item-text {
  // display: flex;
  // gap: 12px;
}