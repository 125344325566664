body {
  margin: 0;
  padding: 0;
  font-family: Roboto;
  font-size: 14px;
  width: 100%;
  height: 100%;
  background-color: #F1F1F1;
  color: #212121;
  overflow: hidden;
}
.ReactModal__Overlay {
  z-index: 999;
}
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: initial;
}

html {
  height: 100%;
  width: 100%;
}

#app {
  height: 100%;
  width: 100%;
}

p {
  margin: 0;
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  width: 100%;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible!important;
}

.ReactVirtualized__Grid {
  overflow: visible scroll!important;
}

.drawer .amv-info {
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-top: 0;
  h1, h2, h3 {
    color:#212121;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    white-space: wrap;
    word-spacing: 2px;
    margin: 0;
    margin-top: 6px;
  }
}