.filter {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 0;
  box-sizing: border-box;
  transition: background-color .3s cubic-bezier(.25, .8, .25, 1);
  cursor: pointer;
}

.filter:hover {
  background-color: rgba(0, 0, 0, .05);
}

.filter.active {
  background-color: rgba(0, 0, 0, .05);
}

.filter-body:last-child {
  margin: 0;
}

.secondary-text {
  margin-top: 5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.info {
  margin-right: 5px;
  font-size: 12px;
  font-weight: 300;
  color: #a0a0a0;
  cursor: pointer;
  position: relative;
  transition: color .5s cubic-bezier(.25, .8, .25, 1);
}

.info:hover {
  color: black;
}

.selected {
  background-color: rgba(0, 0, 0, .05);
}
