.upload-file {
  height: 100%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 100%;
  opacity: 0;
  cursor: pointer;

  &::file-selector-button {
    cursor: pointer;
  }
}

.upload-file-fields {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 24px 0;
}

.upload-container {
  width: 60%;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .upload-container {
    width: 90%;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.close {
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    margin: 0;
    margin-bottom: 14px;
  }

  svg {
    cursor: pointer;
  }
}