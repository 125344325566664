.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.container-drawer {
  display: block;
  padding-top: 0;
  margin-top: 48px;
}

@media only screen and (max-width: 600px) {
  .container-drawer {
    display: none;
  }
}