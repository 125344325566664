.main-area {
  width: 100%;
  height: 100vh;
}

.amv-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  max-height: calc(100dvh - 141px);
  height: 100vh;
  overflow: auto;
  justify-content: center;
  padding-bottom: 20px;
}

.main-area-drawer {
  display: none;
}

@media only screen and (max-width: 600px) {
  .main-area {
    width: 100%;
  }

  .main-area-drawer {
    display: block;
  }
  .amv-grid {
    height: calc(100dvh - 189px)
  }
}