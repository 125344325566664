.landing-video {
  position: absolute;
  top: 0;
  z-index: -99999;
  filter: brightness(50%);
  object-fit: cover;
  margin-left: 50dvw;
  transform: translate(-50%);
  overflow: hidden;
  width: 100dvw;
  height: 100dvh;
}

.main-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
}

h1 {
  font-family: 'Lobster';
  color: white;
  font-size: 72px;
  word-spacing: 30px;
  margin-bottom: 20px;
}

.button-watch:not(:hover) {
  box-shadow: 0 0 23.84px 8.16px rgba(255, 171, 171, 0.39);
}

small {
  font-family: 'Raleway';
  color: white;
  font-weight: 200;
  font-size: 32px;
}

.extension {
  font-family: 'Raleway';
  color: white;
  font-weight: 200;
  font-size: 18px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 29px;
  text-decoration: underline;
  gap: 12px;
  right: 36px;

  img {
    height: 32px;
  }
}

.button-upload-youtube {
  color: #f1f1f1;
  cursor: pointer;
  background: #3eb5f1;
  border: none;
  border-radius: 29px;
  outline: none;
  justify-content: center;
  align-items: center;
  padding: 15px 24px;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 18px;
  font-weight: 500;
  transition: box-shadow .3s ease-out;
  display: flex;
  box-shadow: 0 0 23.84px 8.16px #3eb5f163;
  margin-top: 40px;
  gap: 7px;
  transition: box-shadow .2s;

  &:hover {
    box-shadow: none;
  }
}