.edit-file-fields {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.edit-container {
  display: flex;
  flex-direction: column;
  gap: 22px;
}